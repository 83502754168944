/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.img-header {
  float: left;
  margin-top: 4px;
  margin-right: 4px;
}
.text-header {
  float: left;
  margin-top: 2px;
}
.nav-link.active {
  color: rgba(255, 255, 255, 0.9);
}

/*website capilciamis*/
body {
  font-family: Roboto;
  padding: 0px;
}
/* .horizontalMenucontainer .breadcrumb-header {
  margin-top: 10px !important;
} */

table{
  font-size: 13px !important;
}
table thead th{
  font-size: 13px !important;
}
.main-content.horizontal-content::after {
  height: 230px;
}

.ht-90 {
  height: 50px;
}
.logo-new {
  width: 30px;
  float: left;
}
.mt-20 {
  margin-top: -13px !important;
}
.side-header {
  height: 54px;
}

.he {
  float: left;
  margin-left: 10px;
  font-size: 12px;
  margin-top: 5px;
}
.min-body {
  min-height: 600px;
}
.bg-services {
  border-radius: 6px !important;
  background: rgb(72, 94, 250);
  background: -moz-linear-gradient(
    90deg,
    rgba(72, 94, 250, 1) 0%,
    rgba(125, 114, 252, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(72, 94, 250, 1) 0%,
    rgba(125, 114, 252, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(72, 94, 250, 1) 0%,
    rgba(125, 114, 252, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#485efa",endColorstr="#7d72fc",GradientType=1);
}

.icon-service {
  width: 100px;
  margin: 0 auto;
  padding: 5px;
}

.d-flex {
  display: flex;
  margin-top: -2px;
}
.right-page .value {
  font-size: 13px;
}
.horizontalMenu > .horizontalMenu-list > .menu_aktivasi {
  display: none;
}
.horizontalMenu > .horizontalMenu-list > .menu_lupa_password {
  display: none;
}


@media only screen and (max-width: 480px) {
  .ht-90 {
    height: 400px;
  }
  .side-header {
    height: 0px;
  }
  .he {
    font-size: 10px;
    margin-top: 6px;
  }
  .logo-new {
    width: 28px;
    margin-left: 50px;
  }
  .mt-20 {
    margin-top: 10px !important;
  }
  .d-flex {
    display: block;
  }
  .aktivasi {
    display: none;
  }
  .lupa_password {
    display: none;
  }
  .horizontalMenu > .horizontalMenu-list > .menu_aktivasi {
    display: block;
  }
  .horizontalMenu > .horizontalMenu-list > .menu_lupa_password {
    display: block;
  }
}
@media only screen and (max-width: 680px) {
  .ht-90 {
    height: 70px;
  }
  .he {
    font-size: 10px;
    margin-top: 6px;
  }
  .side-header {
    height: 0px;
  }
  .logo-new {
    width: 28px;
    margin-left: 50px;
  }
  .mt-20 {
    margin-top: 10px !important;
  }
  .breadcrumb-header{
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 820px) {
  .ht-90 {
    height: 70px;
  }
  .he {
    font-size: 9px;
    margin-top: 6px;
  }
  .side-header {
    height: 0px;
  }
  .logo-new {
    width: 28px;
    margin-left: 50px;
  }
  .mt-20 {
    margin-top: 10px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .logo-new {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 1024px) { 
  .side-header {
    height: 53px;
  }
  .horizontalMenucontainer .breadcrumb-header{
    margin-top: 0px !important;
  } 
}




/* @media all and (max-width: 800px), 
only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px), 
only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 1024px), 
only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px), 
only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px), 
only screen and (min-resolution: 192dpi) and (max-width: 1024px), 
only screen and (min-resolution: 2dppx) and (max-width: 1024px){

	.side-header {
    height: 0px;
  }
} */
